.wtIsCombineBuyPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1100px;
    margin: 0 auto
}
.wtIsCombineHeading {
    text-align: center;
}
.wtIsCombineValuePropsRow {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto 20px auto;
}
.wtIsCombineBenefit {
    color:#00B0F0;
    font-style: italic;
}

.wtIsCombineFAQOneRow {
    display: flex;
    margin-bottom: 50px;
    justify-content: center;
}
.wtIsCombineRowQuestion {
    width: 250px;
    font-size: 1.4rem;
    margin-right: 40px;
}

.wtIsCombineRowAnswer {
    color:#3B3838;
    width: 600px;
    font-size: 1rem;
}

@media (max-width: 630px ) {
    .wtIsCombineRowQuestion {font-size: 1.1rem;}
    .wtIsCombineRowAnswer {

        font-size: 0.9rem;
    }
    .wtIsCombineBenefit {
        font-size: 0.9rem;
    }
}