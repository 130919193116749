.checkoutBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    
}
.checkoutBottomBtn {
    background-color: orange;
    font-size: 0.8rem;
    color:white;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
}

.checkoutLinkNoDecoration {
    text-decoration: none;
    cursor: pointer;
}