.sellWithUsSubHeading {
    background-color: bisque;
    font-size: 0.8rem;
    padding: 5px;
    margin: 10px 0px;
    max-width: 500px;
}

.sellWithUsInfoLine{
    font-size: 0.8rem;
    margin: 10px 0;

}