.contactUsContainer {
    margin: 20px auto;
    width: 800px;
    padding: 10px;
    min-height: 75vh;

}

.contactUsHeading{
    font-size: 1.5rem;
}

.contactFormSuccessMessage {
    background-color:rgb(215, 230, 215);
    font-size: 0.8rem;
    padding: 5px;
    margin-top : 10px;
    max-width: 400px;
}

.contactUsSubHeading {
    margin-top: 8px;
    font-size: 0.8rem;
    margin-bottom: 15px;
    color:rgb(105, 105, 105);
    overflow-wrap: break-word;
}
.contactInlineText{
    display: inline-block;
    margin-right: 5px;
    width: 75px;
}
.reasonSelect {
    width: 30%;
    display:inline-block
}

.contactEmailInputRow {
    display: flex;
    margin-top: 5px;
    align-items: center;
}

.contactFormEmailInput {
    border: 1px solid rgb(204, 204,204);
    border-radius: 4px;
    min-height: 30px;
    width: 30%;
}


.contactFormMessageText {
    margin: 5px 0px 0px 12px; 
    height: 200px;
    width: 400px;
    border: 1px solid rgb(204, 204,204);
    border-radius: 4px;
}

.contactFormError {
    color:red;
    font-size: 0.8rem;
   background-color: pink;
   max-width: 400px;
   margin: 15px 0;
   padding: 5px;
}

.contactUsCaptchaBox {
    margin: 10px 0;
}
.contactFormSubmitBtn {
    padding: 5px ;
    background-color: black;
    width: 50px;
    color:white;
    margin: 20px 0;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
}
@media (max-width: 600px ) 
{
    .contactUsContainer {
        width: 325px
    }
    .contactFormContainer{
        width: 300px;
    }
    .reasonSelect {
        width: 60%;
        display:inline-block
    }
    .contactFormEmailInput {width:60%}
    
}