.productBox {
    min-height: 150px;
    max-width: 400px;
    background-color: white;
    margin: 5px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
}

.productImageLinkNoDecoration {
   text-decoration: none;
   width: 65%;
   height:65%;
   margin: 15px 5px;
   cursor: pointer;
   text-align: center;
}

.topProductImg {
 max-width: 100%;
 max-height: 100%; /* we are doing a fixed height so that all the images end at the same height*/
}

.prodNameLink {
text-decoration: none;
color:inherit;
 text-align: center;
 font-size: 75%;
 max-width: 80%;
 margin:0 0  5px 0;
 cursor: pointer;
 font-weight: bold;
}

.prodName { /* not usign it as we have Link as its parent and we are moving all properties there */
     text-align: center;
     font-size: 75%;
     max-width: 80%;
     margin:0 0  5px 0;
    }

.prodSize{
    font-size: .6rem;
    margin: 2px 0;
    max-width: 80%;
}
.prodPriceRow{
    margin: 5px 0px;
    display:flex;
}

.origPrice {
    text-decoration: line-through;
    color: #939292;
    margin-right: 10px;
    font-size: 80%;
}
.offerPrice {
    font-size: 80%;
}

.prodBoxCombineBuyDetailRow {
    font-size: 0.7rem;
    margin-bottom: 5px;
    position: relative;
}
.prodBoxCombineSave{
    font-size: 0.6rem;
    background-color: #ffe17d;
    padding: 4px 3px;
    color: black;
}

.prodBoxInfoIcon {
    border: 1px solid grey;
    border-radius: 10px;
    padding: 0 5px;
    color: grey;
    margin-left: 10px;
    font-size: .65rem;
    cursor: pointer;
}

.prodBoxTooltiptext {
    visibility: hidden;
    width: 330px;
    background-color: rgb(192, 192, 192);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 1px;
    margin-bottom: 10px;
    /* Position the tooltip */
    position: absolute;
    top: -350%;
    left: -20%;
    z-index: 1;
}


.prodBoxInfoIcon:hover + .prodBoxTooltiptext { /* the plus is adjacent class selector*/
    visibility: visible;
    cursor: pointer;
}

.prodBoxCombineBuyDescRow {
    font-size: 0.6rem;
    color: maroon;
    text-align: right;
    width: 100%;
    margin-bottom: 8px;
}
.prodSaving {
    position:absolute;
    top:0;
    right:0;
    background-color: black;
    color: white;
    padding: 4px;
    font-size: .7rem;
}

.prodCartRow {

margin: 5px 0;       
display: flex; 
align-items: center;
justify-content: space-between;
align-content: center;
}



.qtyInput {
    border-style: solid;
    border-color: #dbd9d9;
    border-width: 1px;
    width: 30px;
    border-radius: 4px;
    text-align: center   ;
    font-size: 70%;
    padding: 2px 0;
    margin: 0 8px;
    height: 18px
}


.prodBoxCombineBuyButton {
    font-size: .7rem;
    background-color:#70AD47;
    color:white;
    padding: 6px 6px;
    margin-left: 5px;
    cursor: pointer;
    border: #70AD47 solid 1px;
    font-weight: bolder;
    text-align: center;
}
    
.prodBoxCombineBuyButton:hover {
    color:#70AD47;
    background-color:white;
    
}

.prodBoxAddToCartButton {
    font-size: 65%;
    /* background-color: #FFC000;
    color:white; */
    color: #FFC000;
    background-color: white;
    border: #FFC000 solid 1px;
    font-weight: bolder;
    padding: 6px 6px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
}

.prodBoxAddToCartButton:hover {
    background-color: #FFC000;
    color:white;
}

.prodBoxChoiceText {
    font-size: 60%;
    margin-left: 5px;
    color: grey;
}

@media (max-width: 650px) {

    .prodBoxTooltiptext {
        visibility: hidden;
        width: 280px;
    } 
}