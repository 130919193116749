.searchBtnRowContainer {
    margin: 5px auto;
    max-width: 600px;
    background-color: black;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchInputBttm {
    z-index: 1; /* so that border of the icon next to it doesnt overshadow it when screen is small/squeezed leading to overlaps*/
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: 0px;
    width: 250px;
    min-height: 25px;
    padding: 3px 5px;
    border-color: white;
}

.searchRowTxt {
    color: white;
    font-size: 0.9rem;
    margin: 0 5px;
}

.searchInputBttm::placeholder {
text-align: center;
font-style: italic;

}
@media (max-width:600px) {
    .searchRowTxt {
        font-size: 0.8rem;
    }
    
}