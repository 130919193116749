
.resetPasswordContainer {
    margin: 0 auto;
    max-width: 400px;
    border: solid 1px rgb(221, 221, 221);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.resetPassErrorRow {
    background-color: rgb(248, 196, 196);
    color: red;
    font-size: 0.8rem;
    padding: 5px;
    max-width: 250px;
    margin: 10px auto;
    text-align: center;

}

.nextBtnRow {
    margin-top: 8px;
    display: flex;
    justify-content: center;
}