.orderHistoryContainer {
    padding: 20px;
}

.ordersHistoryHeading{
    color: orange;
    font-weight: 500;
}
.orderOverviewBox {
     margin: 15px auto;
     font-size: 0.8rem;
     width: 300px;
     padding: 10px;
    border: solid 1px rgb(212, 212, 212);
    color: rgb(103, 102, 102);
    border-radius: 5px;
    cursor: pointer;
}

.orderIdRow {
    font-size: 0.7rem;
    font-weight: 700;
    margin-bottom: 8px;
}

.itemsRow {
    color:green;
    font-weight: 500;
}

@media (max-width: 500px ) {
    .orderOverviewBox {
        width:150px
    }
}