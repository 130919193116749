.suModaldarkBG {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 110vh;
    z-index: 0;
    top: -10%;
    /*left: 50%; */
    /* transform: translate(-50%, -50%); */
    position: fixed; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.suModalContainerBox {
    border: 1px solid white;
    background-color: rgb(0, 0, 0);
    color: white;
    z-index: 100;
}

.suModalCloseBtnRow {
    text-align: right;
    margin-right: 10px;
}
.suModalCloseBtn
 {
font-size: 1.2rem;

font-weight: bold;
}
.suModalDialogBox{
    width: 250px;
    min-height: 125px;
    padding: 15px 25px 25px;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
  }

  .suModalInput {
    font-size: 0.75rem;
    color: white;
    font-style: italic;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 10px;
    border: white 1px solid;
    width: 200px;
    border: 1px solid white;
    background-color: black;
    padding: 8px;
    height: 0.75rem;

  }

  .suModalInput::placeholder {
    color:white
  }

  .suModalErrorLine {
    font-size: 0.8rem;
    margin: 2px 0;
    color: red
  }
  .suModalActionRow {
    margin-top: 10px;
    display: flex;
   justify-content: right;
    align-items: end;
  }
  .suModalSUBtn{
    padding: 5px;
    border-radius: 3px;
    margin-right:  3px;
    border: 1px solid #70AD47;
    background-color: #70AD47;
    color: white;
    font-size: .9rem;
  }

  .suModalAgreeTCLine {
    margin: 10px 0 0;
    font-size: 0.5rem;
  }