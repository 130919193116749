.productGridContainer {
    background-color: rgb(250, 250, 250);
    max-width: 1150px;
    margin: 3vh auto;
    color: black;
    padding: 10px;
}

.productsGridMainHeading
{
    font-size: 0.8rem;
    color: grey;
    margin: 15px 0;
}
.productGrid {
    color: black;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    justify-content: center;
    row-gap: 20px;
    column-gap: 15px;
}


@media (max-width: 650px) {
    .productGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, auto));
        justify-content: center;
    }

    .productBox {
        min-height: 60px;
    }
}

@media (max-width: 370px) {
    .productGrid {
        grid-template-columns: repeat(auto-fit, minmax(175px, auto));
        justify-content: center;
    }

    .productBox {
        min-height: 75px;
    }
}