.cartPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1300px;
    margin: 0 auto
}

.cartEmptyMsg {
    margin: 15px auto;
    max-width: 500px;
    text-align: center;
    font-size: 1.5rem;
}

.cartNotPayNowNotice {
    text-align: right;
    font-size: 0.7rem;
    color: grey;
    margin-right: 20px;
    margin-bottom: 2px;
}
.cartHeading {
    margin: 15px 0;
    font-size: 0.9rem;
}
.cartSummaryRow
{
    display: flex;
    flex-direction: row;
    justify-content:left;
}

.cartSummaryMsgContainer {
    flex-grow: 1;
    padding: 5px;
}

.cartSummaryTextAndProceedBtnContainer {
    display: flex;
    flex-direction: row 
}

.cartSummaryBoxMsgsTextContainer {
  width: 60%;
  margin-left: 120px;
  margin-top: 10px;

}
.summaryBoxMsgLine {
    background-color: white;
    margin-top: 8px;
    /* text-align: center; */
    font-size: 1.5rem;
}

.cartSummaryProceedBtnContainer {
    min-width: 200px;
    height: 150px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cartSummaryMsgProceedBtn {
    background-color: orange;
    padding: 8px 20px;
    font-size: 1.3rem;
 cursor: pointer;
    color: white;
    font-weight: bold;
}

.cartSummaryPaymentOptionsImg{
     width: 175px;
     margin-top: 15px ;
}
.cartTableHeading {
    width: 95%;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    background-color: #f7f6f6;

}

.productHeading {
    width: calc(62.5% + 10px);
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.quantityHeading {
    width: 15%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.priceHeading {
    width: 10%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.totalHeading {
    width: 10%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.cartBox {
    padding: 2px;
    display: flex;
    flex-direction: column;
}


@media (max-width: 1165px) { 
    .cartSummaryBoxMsgsTextContainer{
        margin-left: 30px;
    }
    .summaryBoxMsgLine {
        font-size: 1.2rem;

    }
}

@media (max-width: 950px) { 
    .summaryBoxMsgLine {
        font-size: 1rem;
    }
}

@media (max-width: 850px) { 
    .cartTableHeading {
        display: none;
    }
    .cartSummaryTextAndProceedBtnContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .cartSummaryProceedBtnContainer {
        flex-direction: row;
        height: auto;
    }
    .cartSummaryMsgProceedBtn {
    display: none;
    }
}

@media (max-width: 850px) {
    .cartSummaryBoxMsgsTextContainer{
        margin-left: 15px;
        width:auto;
    } 
    .summaryBoxMsgLine {
        font-size: 0.8rem;
    }
    .cartSummaryProceedBtnContainer {
        min-width: 100px;
    }
} 

@media (max-width: 540px) {
    .cartSummaryPaymentOptionsImg{
        margin-top: 10px ;
        height: 35px;
        width: 150px;
   }
}

@media (max-width: 450px) { 
    .cartSummaryBoxMsgsTextContainer{
        margin-left: 8px;
    }
    .summaryBoxMsgLine {
        font-size: .7rem;
    }
}