.orderConfirmationPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1300px;
    margin: 0 auto
}
.orderConfirmedHeading
{
 padding: 15px;
}

.orderConfirmedSubHeading{
    color: grey
    ;
    padding: 5px 5px 15px 0 ;;
    font-size: 0.8rem;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(200, 200, 200);
}

.emailSentRow {
    font-size: .85rem;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(200, 200, 200);
}
.nextStepsBullets {
    list-style-type: square;
}
.nextStepsBullets li {
    color: grey;
    margin: 5px;
    font-size: 0.85rem;
}