.categoriesContainer {
 background-color: rgb(249, 249, 249);  
 max-width: 950px;
 margin: 0 auto;
 color: black; 
 padding: 5px 10px;
}

.categoriesMainHeading
{
font-size: 0.8rem;
margin: 10px 0px;
color: rgb(99, 98, 99);
}
.categoriesGrid {
    color: black; 
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,auto));
    justify-content: center;
}

.noDecorationLink {
    text-decoration: none;
    cursor: pointer;
}
.categoryBox {
    min-height: 150px;
    max-width: 400px;
    background-color: white;
    margin: 5px;
    padding: 10px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.categoryTitle {
    font-size: .75rem;
    color: orange
}

.categoryImage {
    width:90%;
    height: 90%;
    margin: 0 auto;
}

.allCategoriesRow {
    font-size: 0.7rem;
    text-align: end;
    margin: 5px;
    display: flex;
    color: grey;
}
.allCategoriesBtn {
    width: 90px;
    padding: 10px 0px 10px 4px;
    margin-left: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    border: solid 1px grey;
    border-radius: 5px;
}
.allCategoriesBtnArrow {
    font-size: 1rem
}
@media (max-width: 650px ) {
    .categoriesGrid {
        grid-template-columns: repeat(auto-fit,minmax(175px,auto));
        justify-content: center;
       }
       .categoryBox {
        min-height: 75px;
    }
}

@media (max-width: 370px ) {
    .categoriesGrid {
        grid-template-columns: repeat(auto-fit,minmax(125px,auto));
        justify-content: center;
       }
       .categoryBox {
        min-height: 75px;
    }
}