
.outerContainerMain{
    padding:4px;
    border: 2px solid black;
    margin: 0 auto;
    width: 100px;
    height:56px;
    border-right: 2px solid black;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.logoHeroBox{
    background-color: black;
    color: white;
    font-style: italic;
    font-size: 0.75rem;
    padding: 5px 0px;
    width:100px;
    border-radius: 5px;

}
.logoHeroIconTextCol{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoHeroTriangle1 {
    position: absolute;
    left: 101%;
    top:-2px;
    height: 0;
    width: 0;
    border-width: 34px;
    border-style: solid;
    border-color: transparent;
    border-left-color: black;
}
.logoHeroTriangle2 {
    position: absolute;
    left: 100%;
    top:0px;
    height: 0;
    width: 0;
    border-width: 32px;
    border-style: solid;
    border-color: transparent;
    border-left-color: white;
}

.circleLogoHero {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: black;
    position: absolute;
    top:42%;
    right: -18px;
}

