.backBtn {
    text-decoration: none;
    display:inline-block; /*so that it doesnt take the whole row*/
    border-style: solid;
    border-width: 1px;
    padding: 3px 5px;
    margin: 15px 0; /*since its inline only horizontal margin will work*/
    border-color: grey;
    border-radius: 5px;
    color: grey;
    cursor: pointer;
}