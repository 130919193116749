.priceCol {
    font-size: 0.8rem;
    width: 11.5%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;

}

.priceLabel {
    display: none;
}
.origPriceRow {
    text-decoration: line-through;
    color: grey
}

.totalCol {
    font-size: 0.8rem;
    width: 11.5%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.totalLabel {
    display: none;
}

.cartSavingsItems {
    color: grey;
    margin-left: 5px;
}

@media (max-width: 850px) {

    .cartItemImg {
        max-width: 100px;
        min-width: 85px;
        max-height: 100px;
    }

    .nonImgCols {
        flex-direction: column;
        align-items: center;
    }

    .nameDescCol {
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-content: center;
    }

    .qtyCol {
        flex-direction: row;
        width: 100%;
        margin-top: 10px;
justify-content: flex-start;
    }

    .quantityLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }

    .priceCol {
        flex-direction: row;
        width: 100%;
        margin-top: 5px;
        justify-content: flex-start;
    }

    .priceLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }
    .totalCol {
        flex-direction: row;
        width: 100%;
        margin-top: 5px;
        justify-content: flex-start;
    }

    .totalLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }
}