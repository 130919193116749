.orderDetailsContainer{
    padding: 15px;
}
.showAllOrdersBtn {
    font-size: 0.75rem;
    padding: 5px;
    border: 1px solid orange;
    width: 120px;
    border-radius: 5px;
    color: orange;
    cursor: pointer;
    font-weight: 600;
}

.orderDetailBox {
    margin : 20px 0 10px 0;
}

.orderIdHeading {
    margin-top: 15px;
    font-size: 0.9rem;
    font-weight: 700;
}

.itemImageBox{
    height: 85px;
    width: 85px;
    float: left;
    margin-right: 10px;
}
.itemNameRow {
    margin: 5px 0;
    font-size: 0.8rem;
    color: green;
}

.itemPriceRow {
    font-size: 0.75rem ;
    color: grey;
}

.itemStatusRow {
    font-size: 0.75rem ;
    color: purple;
}

.orderDetaialsCancelRow{
    font-size: 0.6rem;
    color: grey
}

.orderCancelBtn {
    font-size: 0.7rem;
    background-color: rgb(196, 196, 196);
    width: 50px;
    padding: 3px;
    text-align: center;
    margin-bottom: 5px;
    color: white;
    display: inline-block;
}