.compactProdListDeliveryNoteContainer {
    background-color: rgb(240, 240, 240);
    font-size: 0.75rem;
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
    color: rgb(101, 101, 101);
    font-family:'Courier New', Courier, monospace;
}
.compactProdCatTitle {
    margin: 0 auto 30px;
    width: fit-content;
    font-family: serif;
    font-size: xx-large;

}

.compactProdListProdsContainer {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
    /* width: 97%; */
    /* background-color: rgb(235, 235, 235); */
}