.productDetailsPageContainer {
    padding: 15px 10px;
    max-width: 1300px;
    min-height: 80vh;
    margin: 0 auto;
}

.productMainContainer {
    margin: 25px  auto 15px auto;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.productImageAndDescBox {
    width: 400px;
    min-height: 400px;
    background-color: white;
    margin: 10px;
    text-align: center;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.prodDescDiscountAmt
 {
    font-size: 0.7rem;
    color: white;
    background-color: black;
    font-weight: 500;
    padding: 3px;
    display: flex;
    align-self: flex-end;
 }
.mainProdImage {
    height: 80%;
    width: 90%;
}
.bottomImgsContainer {
    margin-top: 5px;
    height: 70px;
    width:240px;
    padding: 10px 5px;
    background-color: rgb(235, 235, 235);
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.bottomImgbox {
    width:50px;
    height: 50px;
    background-color: white;
    border: 1px solid grey;
    margin: auto 2px;
    display: inline-block;
}
.bottomImg{
    max-height: 100%;
    max-width: 100%;
  /*  border-radius: 5px; */
}
.imgBottoHelpText {
    font-size: 0.7rem;
    color:grey;
}

#productLongDescriptionSection {
max-width: 800px;
color: grey;
font-size: 0.8rem;
white-space: pre-line;
}

.prodDetailsWtIsCombineBuyContainer {
    max-width: 800px;
    color: grey;
    font-size: 0.8rem;
    margin-top: 15px;
}

.prodDetailsWtIsComineDealHeader {
    color:black;
}