.sellerHomeFirstNotice {
    width:50%;
    margin: 5px auto;
    background-color: green;
    text-align: center;
    color: white;
    padding: 5px;
}

.sellerHomeBannerImageContainer {
    padding: 60px;
    width: 80%;
    margin: 20px auto;
    background-color: grey;
    color: white;
}

