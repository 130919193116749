.productVariantItemContainer {
    width: 50px;
    height: 50px;
    border: 1px solid rgb(103, 103, 103);
    /* display: flex; this causes the image to expand more and doesnt fit in the box 
    align-items: center;
    justify-content: center;*/
    margin: 10px 4px;

}

.productVariantItemImg {
    max-height: 99%;
    max-width:99%;
}