.orderDetailsPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 800px;
    margin: 0 auto
}
.orderDetailsHeading
{
 font-size: 1.3rem;
 margin-bottom: 15px;
}
.orderTotAmtRow{
    color: grey;
    margin: 5px 0;
    font-size: 0.9rem;
}
.orderdetailsItemsContainer{
    margin-top: 15px;
}
.orderDetailsError {
    background-color:rgb(245, 155, 155);
    padding: 10px;
    color:white;
    text-align: center;
}
.orderDetailsLoginRow{
    margin: 15px 0;
    text-align: end;
}
.orderDetailsLoginBtn {
    background-color: orange;
    padding: 5px;
    color:white;
    font-size: 0.9rem;
    cursor: pointer;
}