.categoryPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
}

.categoryNameRow {
    font-size: .9rem;
    margin-bottom: 10px;
}
.prodFiltersRow {
    background-color: white;
    margin: 5px 0;
    padding: 10px 5px;
    display: flex;
    justify-content: space-around;
}

.filterRowFilterText {
    color:orange;
}

.noDecorationLink {
    text-decoration: none;
}


.resultsContainer {
    margin: 15px auto;
    padding: 10px;
    background-color: #f7f6f6;
    max-width: 1175px;
}

.productGrid {
    color: black;
    display: grid;
   /* grid-template-columns: repeat(auto-fit, minmax(250px, auto));*/
    grid-template-columns: 80%;
    justify-content: center;
}

@media (min-width: 450px ) {
    .productGrid {
        grid-template-columns: 45% 45%;
    }

}

@media (max-width: 450px ) {
    .filterRowFilterText {
        display: none;
    }
}

@media (min-width: 1050px ) {
    .productGrid {
        grid-template-columns: 31% 31% 31%;
    }
}
