.signupValidationNeededPageContainer {
    padding: 15px 20px;
    min-height: 75vh;
    max-width: 1300px;
    margin: 0 auto
}

.signupValidationNeededHeader {
    max-width: 800px;
    background-color: rgb(199, 251, 199);
    margin: 0 auto;
    padding: 15px;
    color: green
}

.signupValidationNeededParagraph {
    font-size: 0.8rem;
    max-width: 800px;
    margin: 20px auto 0 auto;
    text-align: justify;
}