.errorPageHeading{
    font-size: 1.5rem;
    color: red;
    background-color: pink;
    padding: 10px;
}
.errorPageSubHeading {
    margin-top: 30px;
    color: black;
    font-size: 1rem;
    display: flex;
    align-items: center;

}