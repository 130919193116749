.outerContainer {
    background-color: black;
}
.innerContainer {
    padding: 10px 15px;
    color: white;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 10px;
    align-items: center;
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
}

.brandNameContainer {
    cursor: pointer;
    margin-right: 20px;
}

.brandLogo {
    height: 100%;
    width: 100%;
}


.searchBar {
    margin: 0 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.searchInput {
    z-index: 1; /* so that border of the icon next to it doesnt overshadow it when screen is small/squeezed leading to overlaps*/
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: 0px;
    width: 50vw;
    min-height: 25px;
    padding: 3px 5px;
    border-color: white;
}

.searchIconContainer {
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    border-width: 0px;
    border-color: white;
}

.headerEnd {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linksContainer {
    display: flex;
}

.links {
    margin: 0 5px;
}

.links:hover {
    opacity: 0.7;
    cursor: pointer;
}

.burgerMenu {
    display: none;
}

.closeIcon {
    font-weight: 800;
    border: solid red 1px;
    padding: 3px;
    border-radius: 3px;
    color: red;
}
.headerLinksHidden {
    z-index: 10000;
    position: absolute;
    right: 0px;
    top: -500px; /* we will keep it out of screen and when menu icon is clicked it top will change*/
    width: 40%;
    text-align: center;
    transition: all .30s ease; /* we have kept it at .3 secs so that it disappears fast*/
}
.hiddenLinks {
    background-color: black;
    padding: 4px 0;
    border-top-width: 1px;
    border-top-color: white;
    border-top-style: solid;
}
.cartIconContainer {
    margin-left: 10px;
    position: relative;
}
.itemCount {
    position: absolute;
    top:-8px;
    right:-7px;
    
    height:15px;
    width: 15px;
    background-color: orange;
    color:white;
    font-size: 0.7rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.noDecorationLink {
    color:inherit;
    text-decoration: none;
}
.hiddenLinks:hover {
    background-color: rgb(201, 200, 200);
    color: black;
     cursor:pointer;
}

.headerLogoImg {
    height: 40px
}


@media (max-width:975px) {
    .container {
        padding: 10px 5px;
    }
    .links {
        font-size: .8rem;
    }
    .cartIconContainer {
        margin-left: 0px;
    }
    
}
@media (max-width:900px) {
    .searchInput {
        width: 40vw;
    }
    
}

@media (max-width:750px) {
    .searchInput {
        width: 35vw;
    }
    .headerLogoImg {
        width: 110px;
        height: 30px;
    }
    .brandNameContainer {
        margin-right: 0px;
    }
    
}
/* show the burger menu uptil the screen is 500px and keep the Text links in the header hidden*/
@media (max-width:550px) {
    .burgerMenu {
        display: block;
    }

    .headerLinksHidden {
        top: -500px;
    }

    .headerLinksHidden.active {
        transition: all .50s ease;
        top: 100%;
      }

    .linksContainer {
        display: none
    }
    .searchInput {
        width: 33vw;
    }
    .cartIconContainer {
        margin-left: 7px;
    }
}

@media (max-width: 340px) {
    .itemCount {
        right: -1px;
        font-size: 0.5rem;
    }   
}

@media (max-width: 475px) { 
    .brandNameContainer {
        margin-right: 0px;
    }
    .headerLogoImg {
        height: 30px
    }
}