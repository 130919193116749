.inviteContainer{
    padding: 15px;
}


.inviteHeading{
    color: orange;
    font-weight: 500;
    text-align: center;
}

.inviteRewardsIcon{
    width: 30px;
    text-align: center;
}

.inviteSubHeading {
    color: black;
    font-size: 0.75rem;
    margin-top: 10px;
    text-align: center;
}

.inviteStepsOuterContainer {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgb(246, 246, 246);
}
.inviteStepContainer {
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 10px;
    margin-top: 25px;
    background-color: white;
}
.inviteStepsIcon {
    width: 50px;
    margin: 10px 0 0 0;
}
.inviteStepsHeadingRow{
    display: flex;
    margin-top: 10px;
    align-items: center;
}
.inviteNumberBubble {
    margin: 0 5px;
    width: 25px;
    height: 25px;
    border-radius: 12px; 
    font-size: .8rem;
    font-weight: bold;
    color: white;
    background-color: orange;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.inviteWriteupHeading {
    font-size: 0.7rem;
    color: orange;
    display: inline-block;
    font-weight: 600;
    width: 170px
}
.inviteStepNormalWriteup{
    margin-top: 15px;
    font-size: 0.6rem;
    width: 180px;
    color: grey;
    text-align: justify;
}

.invitationDescriptionBox {
    margin-top: 20px;
    font-size: 0.8rem;
    color: grey
}
.inviteLink {
    color: green;
    padding : 10px;
    font-size: 0.7rem;
    border: 3px double rgb(193, 193, 193);
    border-radius: 3px;
    margin: 10px 0 0 0;
    overflow-wrap: break-word;
    width: 150px;
}

.inviteContainer ul {
    padding: 0
}

.inviteContainer ol {
    margin-bottom: 15px ;
    padding: 0
}

.copyLinkBtn {
    margin: 10px auto 0 auto;
    background-color: green;
    width:75px;
    padding: 8px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-size: 0.7rem;
}
@media (max-width: 650px ) {
    .inviteContainer{ max-width: 300px}
}


@media (max-width: 360px ) {
    .inviteContainer{ max-width: 220px}
}