
.loginContainer {
    margin: 0 auto;
    max-width: 400px;
    border: solid 1px rgb(221, 221, 221);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.loginHeader {
    color: orange;
    text-align: center;
}

.loginErrorRow{
    color:red;
    font-size: 0.8rem;
    margin: 20px auto 5px auto;
}

.loginBtnRow {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

@media (min-width: 700px) {
    .loginContainer {
        padding: 50px 15px;
    }
    .loginHeader {
        margin-bottom: 20px;
    }
    .loginBtnRow {
        margin-top: 30px;
        margin-bottom: 20px;
    }
}