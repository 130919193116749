.productSizeBoxOptionContainer {
    border: 1px solid rgb(187, 187, 187);
    padding: 10px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.productSizeBoxOptionContainerSelected {
    border: 1px solid rgb(187, 187, 187);
    padding: 10px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.prodSizeBoxOptionsItem {
    font-size: .7rem;

    color: black;
}

.prodSizeBoxOptionsItemSelected {
    font-size: .8rem;
    cursor: pointer;
    color: white;
background-color: black;
}