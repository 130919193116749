.checkoutProceedToCheckoutContainer {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin : 5px auto;
    padding: 10px;
    font-size: 0.8rem;
    color:grey;
}
.checkoutCartSummaryBox {
    border: 1px solid rgb(221, 221, 221);
    padding: 5px
}

.checkoutSummaryRow {
    text-align: center;
    margin: 5px 0;
    background-color: black;
    color: white;
    padding: 5px;
}
.checkoutQtyTotRow {
    margin: 5px
}
.checkoutGrandTotRow {
    margin: 5px
}

.checkoutSummaryLinkNoDecoration {
    text-decoration: none;
}

.placeOrderSummaryBtn {
    background-color: orange;
    color: white;
    padding: 5px;
    text-align: center;
}