.checkoutPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1300px;
    margin: 0 auto
}

.addressHeader {
    display: flex;
    background-color: orange;
    padding: 5px;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    font-weight: 700;
    gap: 8px;
    margin-bottom: 10px;
}

.checkoutAddressPaymentParentContainer {
    display: flex;
    justify-content: center;
}

.addressBodyMain {
    border: 1px solid rgb(227, 226, 226);
    max-width: 400px;
    margin: 2px auto;
}

.redAsterisk{
    color: red;
}

.addressInputContainer {
    padding: 10px 15px 5px 15px;
    font-size: 0.8rem;
    color: rgb(120, 120, 120);
}


.inputBox {
    margin-top: 8px;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgb(227, 226, 226);
    width: 100%;
    ;
}

.cartErrorSubmission{
    color: red;
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
}
.checkoutCaptchaBox {
    margin-top: 10px;
    width: 100%; 
    display: flex;
    justify-content: center;
}
.termsCheckContainer {
    width: 250px;
    font-size: .75rem;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
}

.termsLinkcheckout{
    color:skyblue;
    cursor: pointer;
}
.placeOrderBtn {
    margin: 20px auto;
    width: 100px;
    background-color: orange;
    text-align: center;
    padding: 5px;
    font-size: 0.8rem;
    color: white;
    cursor: pointer;
}

.disclaimerText {
    color: rgb(120, 120, 120);
    font-size: 0.8rem;
    text-align: center;
}

.checkoutPaymentSectionContainer {
    min-width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 15px;

}

.checkoutPaymentHeader {
    background-color: grey;
    width: 100%;

    color: white;
    padding: 10px 0;
    text-align: center;
}
.checkoutPaymentAuthoriseLine {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
    color: grey;

}

.checkoutPPbutton {
    background-color: #FCC439;
    color: #042E80;
    font-weight: bold;
    padding: 5px 65px;
    font-style: italic;
    border-radius: 5px;
    margin-bottom: 5px;   
}

.checkoutPPbutton:hover {
    cursor: pointer;
    background-color: #cda02f;
}

.checkoutPPButtonPal{
    color: #259CDE
}

.checkoutCardsbutton {
    margin-top:5px;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
    background-color: #2C2E2F;
    padding: 5px 20px;
    border-radius: 5px;
}

.checkoutCardsbutton:hover {
    cursor: pointer;
    background-color: #535759;
}

.checkoutPaymentLoaderContainer {
    position: fixed;
    top:45%;
    right:45%;
    background-color: rgb(195, 194, 194,0.2);
}

@media (max-width: 560px ) {
    .checkoutAddressPaymentParentContainer{
flex-direction: column;
    }
    .checkoutPaymentSectionContainer {
        margin-top: 15px;
    }
}