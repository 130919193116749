.howItWorksPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
}

.howItWorksHeading {
    margin-top: 15px;
    width: 265px;
    font-size: 1rem;
    margin: 0 auto
}

.howItWorksSubHeading {
    color: grey;
    font: .7rem sans-serif;
    margin-top: 10px;
    width: 265px;
    margin: 10px auto;
}

.howItWorksContentsContainer {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.howItWorksColumnSmall{
    width: 300px;
}
.howItWorksSmallIcon {
    height: 45px;
    width: 45px;
    margin: 25px auto 15px auto;
    display: block;
}
.howItWorksHeadingContainer{
    display: flex;
    align-items: center;
}
.howItWorksNumberBubble{
    margin: 0 5px;
    width: 30px;
    height: 30px;
    border-radius: 15px; 
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: orange;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.howItWorksWriteupHeading {
    font-size: 0.9;
    color: orange;
    display: inline-block;
    font-weight: 600;
}
.howItWorksContent{
    font-size: 0.7rem;
    margin: 15px 10px 0px 25px;
}
.howItWorksColumnLarge{
    width:300px;
    background-color: white;
}
.howItworksIconsContainer {
    text-align: center;
}
.howItWorksSmallInlineIcon {
    height: 45px;
    width: 45px;
    margin: 15px auto 15px auto;
    display: inline;
}
.howItWorksTruckIcon
{
    height: 45px;
    width: 75px;
    margin: 18px auto 15px auto;
    display: inline;

}

.extraFeaturesContainer {
    
    margin: 40px auto ;
    width: 340px;
    background-color: rgb(255, 218, 150);
    font-size: 0.7rem;
    padding:10px;
}
.extraFeaturesRow {
    display: flex;
    align-items: center;
}
.returnsImgIcon {
    width: 30px;
    display: inline-block;
}
@media (min-width: 510px) {
    .howItWorksSubHeading {
        width: 500px;
    }
}