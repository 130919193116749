.privacyPolicyContainer{
    max-width: 600px;
    margin: 25px auto 10px auto;
    
}

.privacyPolicyDescription {
    margin: 0 0 15px 0;
    text-align: justify;
}
.privacyPolicyli {
    margin: 10px 0;
    text-align: justify;
}
.privacyPolicyContactUs{
    color: skyblue;
    cursor: pointer;
}