.logoHeaderContainer {
    height: 100%;
    display: flex;
    position: relative
}

.logoHeaderTriangle1 {
    position: absolute;
    left: 100%;
    height: 0;
    width: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent;
    border-left-color: black;
}

.logoHeaderBox {
    border: 2px solid white;
    padding-left: 2px;
    background-color: black;
    display: flex;
    position: relative;
    height: 40px
}

.logoTextContainer {
    height: 100%;
    width: 65px;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 600;
    font-size: .6rem;
    background-color: black;
    justify-content: center;
}

.cartIconLogoHeader {
    position: absolute;
    right: 2px;
    top: 25%
}

.circleLogoHeader {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: white;
    position: absolute;
    top:40%;
    right: -10px;
}

.logoHeaderTriangle2 {
    height: 0;
    width: 0;
    border-width: 22px;
    border-style: solid;
    border-color: black;
    border-left-color: white;
}