.verifyEmailPageContainer{
    min-height: 75vh;
}
.signupVerifiedTxtContainer{
    max-width: 800px;
    margin: 10px auto;
    background-color: rgb(206, 255, 206);
    color: green;
    padding: 20px
}


.FailedSignupVerifiedTxtContainer {
    max-width: 800px;
    margin: 10px auto;
    background-color: rgb(249, 233, 233);
    color: red;
    padding: 20px;
    text-align: center;
}