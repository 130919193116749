
.cookieBannerContainer {
    position: absolute;
width: 100%;
background-color: rgba(256,256, 256, 1);
border-top: 1px solid rgb(128,128,128,0.3);
border-bottom: 1px solid rgb(128,128,128,0.3);
position: fixed;
bottom: 50px;
min-height: 2vh;
}
.phantomStyle{
    height: 8vh;
}
.cookieBannerMsg {
    padding: 0 5px;
    max-width: 1000px;
    margin: 10px auto;
    font-size: 0.6rem;
}
.privacyPolicyLink {
    color: skyblue;
    display: inline;
    cursor: pointer;
}
.agreeCookieUsageBtn{
    padding: 5px;
    background-color: orange;
    width: 100px;
    margin: 15px auto 10px auto;
    text-align: center;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
}
@media(max-width:600px) {
    .phantomStyle{
        height: 25vw;
    }
}