.signupContainer {
    margin: 0 auto;
    max-width: 400px;
    border: solid 1px rgb(221, 221, 221);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.signupHeader {
    color: orange;
    text-align: center;
}

.signupDetailsRow {
    margin-top: 15px;
    font-size: .7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70%;

}

.signupRowLabel {
    display: inline;
    margin-right: 15px;
    width: 80px;
    color: grey;
}

.signupRowInput {
    border: none;
    border-bottom: 1px solid rgb(227, 226, 226);
    border-radius: 5px;
}

.signupCaptchaBox {
    margin-top: 10px;
    transform:scale(0.75); 
    transform-origin:(0 0);
    display: flex;
    justify-content: center;
    align-content: center;
}
.termsRowSignup {
    font-size: 0.7rem;
    text-align: center;
}
.termsLinkSignup {
    color: skyblue;
    cursor: pointer;
}

.signupError {
    color:red;
    text-align: center;
    font-size: 0.7rem;
    margin-top: 5px;
}
.signupBtnRow {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.singupBtn {
    padding: 6px 6px;
    background-color: orange;
    font-size: 0.7rem;
    color: white;
    cursor: pointer;
    font-weight: 600;
}

.loginInsteadRow {
    margin-top: 20px;
    text-align: center;
    font-size: 0.7rem;
}

.loginSignupLink {
    display: inline;
    color: blue;
    font-size: 0.85rem;
    cursor: pointer;
    background-color: rgb(252, 226, 178);
    padding: 3px;
}