.categoryPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
}

.noDecorationLink {
    text-decoration: none;
}


.resultsContainer {
    margin: 15px auto;
    padding: 10px;
    background-color: #f7f6f6;
    max-width: 1175px;
}