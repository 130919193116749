.compactProductBoxContainer {

   background-color: white;
    margin-bottom: 15px;
    text-align: center;
}
.compactProductBoxImg {
    /* max-width:100%; */
 /* max-height:90%; */
 width: 190px;
 align-self: start;
}
.compactProdBoxProdName {
    font-size: 0.75rem;
    width: 100%;
    font-family:'Times New Roman', Times, serif;
}

.compactProductPriceRow {
    margin: 5px 0;
}
.compactProductOrigPrice {
    font-size: 0.7rem;
    text-decoration: line-through;
    color: #939292;
}

.compactProductOfferPrice {
font-size: 0.7rem;
margin-left: 3px;
}