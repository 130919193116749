.prodDescContainer {
    position: relative;
    width:100%;
    height:100%;
    border-bottom: 1px solid rgb(222, 218, 218);
}
.prodNameHeader {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.prodDescRow{
    font-size: 0.8rem;
    color:grey;
    margin-bottom: 10px;
    display: block;
}
.prodDetailsOneRow{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    color:grey;
    display: flex;
    position: relative;
    align-items: center;
}
.prodDetailslabel {
    margin-right: 5px;
}

.prodDescAddToCartContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qtyCartRow{
    margin: 10px 0px;
    display: flex;
    justify-content: center;
}
.combineBuyQtyCartRow{
    display: flex;
    justify-content: center;
}

.qtyIncrDcrBtn {
    border : solid 1px rgb(222, 222, 222);
    width: 25px;
    color: grey;
    font-weight:600 ;
    cursor: pointer;
}

.prodDescAddToCartBtn {
    background-color: orange;
    font-size: 0.8rem;
    padding: 4px 6px;
    color: white;
    cursor: pointer;
}

.prodDescNoteContainer {
    color: grey;
    /* position:absolute;
   bottom:0;
    right:0;*/
    font-size: 0.6rem;
    margin-top: 15px;
}

.prodDescNotesBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.prodDetailsInfoIcon {
    border: 1px solid grey;
    border-radius: 12px;
    padding: 4px 8px;
    color: grey;
    margin-left: 10px;
    font-size: .6rem;
    cursor: pointer;
}

.prodDetailsTooltiptext {
    visibility: hidden;
    width: 330px;
    background-color: rgb(192, 192, 192);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 1px;
    margin-bottom: 10px;
    /* Position the tooltip */
    position: absolute;
    top: -200%;
    z-index: 1;
}


.prodDetailsInfoIcon:hover + .prodDetailsTooltiptext { /* the plus is adjacent class selector*/
    visibility: visible;
    cursor: pointer;
}

.prodDetailsCombineBuyDescRow {
    font-size: 0.7rem;
    color: maroon;
    text-align: right;
    width: 100%;
    margin-bottom: 12px;
}

.combineBuyProdDescAddToCartContainer{
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    margin: 15px 10px 0 10px;
}
.comebineBuyProdDescCombineBuyBtn {
    background-color: #70AD47;
    font-size: 0.8rem;
    padding: 4px 6px;
    color: white;
    cursor: pointer;
    margin: 0 4px 0 8px; 
    border: 1px solid #70AD47;
}

.comebineBuyProdDescCombineBuyBtn:hover {
    color: #70AD47;
    background-color: white;
    border: 1px solid #70AD47;
}

.combineBuyBtnSubText {
    font-size: 0.7rem;
    color:grey;
    font-style: italic;
    margin-top: 2px;
}
.combineBuyChoiceText {
    color: grey;
    margin: 0 2px;
    font-size: .8rem;
    padding: 3px 2px
}

.comebineBuyProdDescBuyNowBtn{
    background-color: white;
    font-size: 0.8rem;
    padding: 4px 6px;
    color: orange;
    cursor: pointer;
    margin: 0 4px; 
    border: 1px solid orange;
}

.comebineBuyProdDescBuyNowBtn:hover{
    background-color: orange;
    color: white;
}

.combineBuyDetailsContainer {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 20px;
}
.combineBuyDetailswriteup{
    color:grey;
    margin-top: 2px;
}
.combineBuyDetailWtIsCombineBuy {
    margin-top: 10px;
    color: rgb(71, 71, 198);
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
}