.containerHero {
    margin: 15px auto 15px auto;
    background-color: white;
    max-width: 1000px;
    color: grey;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.heroTextHeading {
    color: orange;
    font-weight: 600;
    position: relative;
    top:20px;
    background-color: white;
    max-width: 120x;
    margin: auto;
    padding: 0 5px;
}
.containerHero h2 {
    display: inline
}

.heroLogoImage {max-height:60px}
.heroBrandLogo {
    display: inline;
    height: 55px;
    width: 50px;
}

.heroText {
    margin-top: 6px;
    color: black;
    font-size: .75rem;
}
.textHeroContainer{
    max-width: 600px;
    margin: 0 auto;
    padding:15px 5px;
    margin-top: 10px;
    border: 1px solid rgb(196, 196, 196);
    font-size: 0.7rem;
    font-family: 'Bahnschrift', sans-serif;                    
}

.heroNoDecorationLink {
    text-decoration: none;
}
.howItWorksHero{
    margin-top: 8px;
    font-size: 0.7rem;
    font-family: 'Bahnschrift', sans-serif;    
    color: rgb(44, 129, 234);
    cursor: pointer;
}

.sellWithUsHero{
    margin-top: 4px;
    font-size: 0.7rem;
    font-family: 'Bahnschrift', sans-serif;    
    color: rgb(234, 177, 44);
    cursor: pointer;
    text-align: end;
}
@media (max-width: 450px) {
    .containerHero {
        max-width: 80vw;
    }
}