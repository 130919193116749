.darkBG {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .basicModalDialogBox{
    min-height: 125px;
    width: 250px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    color: orange;
    z-index: 10;
  }

  .basicModalSecondaryText {
    font-size: 0.75rem;
    color: grey;
    font-style: italic;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .basicModalActionRow {
    margin-top: 10px;

    height: 20px;
    display: flex;
   justify-content: right;
   align-items: end;
  }
  .basicModalSwitchBtn{
    padding: 3px;
    border-radius: 3px;
    margin-right:  3px;
    border: 1px solid #70AD47;
    background-color: #70AD47;
    color: white;
    font-size: .9rem;
  }
  .basicModalCancelBtn {
    padding: 3px;
    border-radius: 3px;
    margin-right:  3px;
    border: 1px solid grey;
    background-color: white;
    color: grey;
    font-size: 0.9rem;
  }