.logoutBtnContainer{
    padding: 15px;
}

.confirmationHeading {
    margin-top: 25px;
    color: rgb(80, 79, 79);
    text-align: center;
}

.logoutBtn {
    background-color: black;
    color: white;
    width: 75px;
    margin: 10px auto;
    padding: 5px;
    font-size: 0.9rem;
    cursor: pointer;
}