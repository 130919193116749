.accountDetailsContainer {
    display: flex;
justify-content: center;
margin-top: 20px;
}

.selectionsCol{
    width: 200px;
    background-color:rgb(250, 250, 250);
    color: rgb(100, 100, 100);
    
}


.outputCol {
    width: 700px;
    min-height: 70vh;
    border: solid 1px rgb(214, 214, 214);
    border-left: none;
}

.selectionItem {
    text-align: center;
    font-size: 0.8rem;
    padding: 15px 0px;
    border-right: solid 1px rgb(214, 214, 214);
}
.activeSelection {
    background-color: rgb(152, 152, 152);
    color: white;
    border-right: none;
}
.selectionItem:hover {
    background-color: grey;
    color: white;
    cursor: pointer;
}