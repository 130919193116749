.bestDealsOuterContainer {
    max-width: 970px;
    margin: 10px auto;
}

.bestDealHeading {
    margin-top: 15px;
    color: black;
    margin: 10px 5px;
    font-size: 0.9rem;
}
.bestDealsInnerContainer {
    padding: 5px 10px;
    max-height: 350px;
    max-width: 950px;
    background-color: rgb(249, 249, 249);
    white-space: nowrap;
    /* This ensures that the element doesnt go down to next row and hence scroll only horizonally*/
    overflow-x: scroll;
    /* This will show the scroll bar horizontal*/
}

.bestDealsCategoryBox {
    min-height: 150px;
    max-width: 325px;
    /* change it to max later on*/
    background-color: white;
    margin: 0 5px;
    padding: 10px 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.bestDealsDiscountAmt {
    background-color: red;
    width: 60px;
    color: white;
    font-size: .8rem;
    padding: 3px;

}

.bestDealsProductImage {
    width: 60%;
    max-height: 60%;
    display: block;
    margin: 0 auto; /* here auto wont work because image is not block element*/
}

.bestDealsProductName {
    font-size: 0.7rem;
    margin: 5px auto;
    color: green;
    width: 75%;
    white-space: normal;
    text-align: center;
}

@media (max-width:600px) {
    .bestDealsInnerContainer {
        max-height: 200px
    }

    .bestDealsCategoryBox {
        width: 165px;
    }

    .bestDealsDiscountAmt {
        width: 40px;
        font-size: .6rem;
        padding: 3px;
    }
}