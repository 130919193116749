.footerContainer {
    min-height: 8vh;
    background-color: black;
text-align: center;
    color:white;
    padding: 10px 0;
}

.footerLinksContainer {

    margin: 10px auto;
    display: flex;
    width: 600px;
    justify-content: space-between;
    font-size: 0.9rem;
}
.footerNoDecoratonLink {

    text-decoration: none;
    color: inherit
}

.footerlink{

    text-align: left;
    margin-bottom: 5px;
    cursor: pointer;

}
.footerlink:hover {
transform: translateX(-10px);
transition: all .50s ease;
}

.footerCopyRightLine {
    font-size: 0.8rem;
}

@media(max-width: 600px) {
    .footerLinksContainer {
        width: 300px;
        font-size: 0.6rem;
    }
}