.filtersContainer {

    font-size: 0.8rem;
    color: green;
    padding: 1px 0;
    position: relative;
}
.filterSelectBox {
    border: 1px solid green;
    border-radius: 3px;
    background-color: white;
    padding: 3px 4px;
    color: black;
    width: 100px;
    cursor: pointer;
}
.filterSelectOptionColumn {
    position: absolute;
    top: 115%;
    right:0%;
    z-index: 99999;
    display: none;
 }

 .showSelectionColumn {
    display: block;
    transition: all .50s ease;
 }
 .fitlerOption {
    border: 1px solid white;
    background-color: rgb(244, 244, 244);
    color: black;
    border-radius: 3px;
    width: 55px;
    text-align: center;
    padding: 3px;
    font-size: 0.7rem;
}
.fitlerOption:hover {
     cursor: pointer;
    background-color: rgb(87, 87, 87);
    color: white;
 }

 @media (max-width: 450px ) {
    .fitlerOption {
        font-size: 0.9rem;
    }
}