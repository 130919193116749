.invitePageContainer{
    padding: 25px 10px 15px 10px;
    min-height: 80vh;
}


.invitePageLoginMessage {
background-color: bisque;
padding: 8px;
}

.invitePageLoginMessageHeading {
    font-size: .8rem;
    color: black;
    max-width: 400px;
    margin: 50px auto;

}

.invitePageLogoutBtn {
    border: 1px solid grey;
    border-radius: 3px;
    width: 60px;
    text-align: center;
    padding: 5px;
    margin: 10px auto;
    cursor: pointer;
}

.invitePageSignupMessageHeading {
    background-color: bisque;
    max-width: 500px;
    margin: 10px auto;
    font-size: 0.8rem;
    padding: 8px;
}