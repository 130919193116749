.passwordChangeContainer{
    padding: 15px;
}

.passwordChangeHeading{
    color: orange;
    font-weight: 500;
}

.passwordChangeFormBox {
    margin: 10px auto;
    width: 300px;
    border: 1px solid rgb(199, 199, 199);
    padding: 10px;
    border-radius: 5px;
}

.changePasswordRow {
    margin-bottom: 5px;
    color: grey;
}
.changePasswordLabel {
    font-size: 0.8rem;
    display: inline-block; /* because we also want to set width*/
    margin-right: 10px;
    width: 120px;
}

.changePasswordInput {
    border: none;
border-bottom: 1px solid black;
}

.changePasswordErrorRow {
    color:red;
    font-size: 0.8rem;
    text-align: center;
}
.changePasswordBtn{
    font-size: .7rem;
    color: white;
    padding: 5px;
    background-color: orange;
    width: 40px;
    margin: 10px auto 0;
    border-radius: 3px;
}

@media (max-width: 500px ) {
    .passwordChangeFormBox {
        width:200px
    }
    .changePasswordRow {
        margin-bottom: 15px;
    }
    .changePasswordInput {width: 130px}
}